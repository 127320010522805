<template>
    <div>

        <div class="columns is-mobile is-multiline">
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Name') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               :placeholder="i18n('name')" v-model="badge.name">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Points') }}</label>
                    <div class="control">
                        <input class="input" type="number"
                               :placeholder="i18n('points')" v-model="badge.points">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Select Program') }}</label>
                    <vue-select :source="route('programs.options')"
                                v-model="badge.program_id"
                                :http="http"
                                track-by="id"
                                :placeholder="i18n('Pick an option')"/>
                </div>
            </div>
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Choose an File') }}</label>
                    <div class="control">
                        <label class="file-label">
                            <input class="file-input"
                                type="file"
                                accept=".webp, .jpg, .jpeg, .avif, .png, .svg"
                                size="2000"
                                @change="handleFileUpload">
                            <span class="file-cta">
                                <span class="file-label">
                                    {{ i18n('Choose an File') }}...
                                </span>
                            </span>
                            <span class="file-name">
                                {{ fileName || i18n('No file chosen') }}
                            </span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="column is-full-mobile is-full-tablet">
                <a class="button-alignment" @click="fetchSave">
                    <button class="button butt-save">
                        {{ i18n('Save') }}
                        <i class="i-icon fas fa-check"/>
                    </button>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { VueSelect } from '@enso-ui/select/bulma';

export default {
    name: 'Create',
    components: { VueSelect },
    inject: ['http', 'i18n', 'errorHandler', 'route'],

    data: () => ({
        badge: {
            name: null,
            points: null,
            program_id: null,
            file: null,
        },
        fileName: '',
    }),

    methods: {
        handleFileUpload(event) {
            // eslint-disable-next-line prefer-destructuring
            this.badge.file = event.target.files[0];
            this.fileName = event.target.files[0].name;
        },

        fetchSave() {
            const formData = new FormData();
            formData.append('file', this.badge.file);
            formData.append('name', this.badge.name);
            formData.append('points', this.badge.points);
            formData.append('program_id', this.badge.program_id);
            const headers = { 'Content-Type': 'multipart/form-data' };
            this.http.post(this.route('badge.store'), formData,
                { headers })
                .then(() => {
                    this.$router.push('/badge');
                }).catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/enso.scss';

.butt-save{
    background-color: $limeGreen;
    border-color: transparent;
    color: $secondary !important;
}

.button-alignment{
    padding-top: 33px;
}

.styleInput{
    background-color: transparent;
    border-color: transparent;
}

</style>
